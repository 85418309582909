const FontFaceObserver = require("fontfaceobserver");

const font = new FontFaceObserver("utopia-std", {
  weight: 400,
});

font.load().then(
  function () {
    document.documentElement.classList.add("fonts-loaded");
  },
  function () {}
);
